.App {
  text-align: center;
  background-color: #1a1a1a;
  color: #ffffff;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  padding: 20px;
}

.App-logo {
  height: 60vmin;
  max-height: 300px;
  pointer-events: none;
}

.App-header {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: #282c34;
  padding: 20px;
  margin-bottom: 20px;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
}

.App-link:hover {
  color: #21a1f1;
}

.socials {
  margin-top: 10px;
}

.social-icon {
  color: #61dafb;
  font-size: 2em;
  margin: 0 10px;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #21a1f1;
}

main {
  margin: 20px 0;
}

section {
  background-color: #333;
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

h2 {
  color: #61dafb;
}

.tokenomics-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tokenomics-logos, .how-to-buy-logos {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.token-logo, .buy-logo {
  width: 50px;
  margin: 0 10px;
}

footer {
  margin-top: 20px;
  font-size: 0.8em;
}

.tokenomics {
  background-color: #444;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.tokenomics-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tokenomics-item {
  background-color: #555;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  width: 80%;
  text-align: center;
}

.tokenomics-item h3 {
  margin: 0;
  color: #61dafb;
}

.tokenomics-item p {
  font-size: 1.5em;
  color: #ffffff;
}

.how-to-buy {
  background-color: #444;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.how-to-buy-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-to-buy-item {
  background-color: #555;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  width: 80%;
  display: flex;
  align-items: center;
}

.how-to-buy-item p {
  margin-left: 10px;
  font-size: 1.2em;
  color: #ffffff;
}

.buy-logo {
  width: 40px;
}

h1 {
  margin: 10px 0;
}

p {
  margin: 5px 0;
}

/* About Section Styling */
.about {
  background-color: #444; /* Darker background for about section */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside the section */
  margin: 20px 0; /* Margin above and below the section */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for depth */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items */
}

.about p {
  background-color: #555; /* Slightly lighter background for paragraphs */
  border-radius: 8px; /* Rounded corners for paragraphs */
  padding: 15px; /* Padding inside paragraphs */
  margin: 10px 0; /* Space between paragraphs */
  width: 80%; /* Width of the paragraph containers */
  text-align: center; /* Center text */
  color: #ffffff; /* White color for text */
  font-size: 1.1em; /* Slightly larger font for readability */
}

/* About Section Item Styling */
.about-item {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items */
  margin: 10px 0; /* Space between items */
}

.about-meme {
  width: 80%; /* Set a width for memes */
  max-width: 300px; /* Max width for memes */
  margin-top: 10px; /* Space above memes */
  border-radius: 8px; /* Rounded corners for memes */
}

/* Meme Gallery Styling */
.meme-gallery {
  display: flex; /* Use flexbox for layout */
  flex-wrap: wrap; /* Allow wrapping of items */
  justify-content: center; /* Center align items */
  margin-top: 20px; /* Space above the gallery */
}

.gallery-meme {
  width: 150px; /* Set a width for gallery memes */
  margin: 10px; /* Space between memes */
  border-radius: 8px; /* Rounded corners for memes */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

@keyframes shooting-star {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh) translateX(-100vw);
    opacity: 0;
  }
}

.shooting-star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: white;
  border-radius: 50%;
  animation: shooting-star 1s linear infinite;
}

/* Add a container for the stars */
.stars-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allow clicks to pass through */
  overflow: hidden; /* Prevent overflow */
}

/* Typewriter effect */
.typewriter {
  overflow: hidden; /* Ensures the text is hidden until revealed */
  border-right: .15em solid orange; /* Creates the cursor effect */
  white-space: nowrap; /* Prevents text from wrapping */
  margin: 0 auto; /* Centers the text */
  letter-spacing: .15em; /* Adds space between letters */
  animation: typing 3s steps(30, end), blink-caret .75s step-end infinite;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: orange; }
}

/* Explosion effect */
.explode {
  animation: explode 0.5s forwards;
}

@keyframes explode {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}